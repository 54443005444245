import React from 'react';
import { Box, Typography, Divider } from '@mui/material';

const Guidelines: React.FC = () => {
  const guidelines = [
    {
      title: "Respect the Rhythm 🎧",
      description: "Support each other with kindness and constructive feedback."
    },
    {
      title: "Be Original 🌟",
      description: "Share your own beats. No copying or spamming."
    },
    {
      title: "Keep It Clean 🎶",
      description: "No offensive or harmful content—everyone should feel welcome."
    },
    {
      title: "Spread the Energy 🚀",
      description: "Encourage others, leave thoughtful comments, and share the love."
    },
    {
      title: "Create Fearlessly 🌍",
      description: "Whether you're a pro or just starting, your beats belong here!"
    }
  ];

  return (
    <Box
      sx={{
        borderRadius: 2,
        p: 2
      }}
    >
      <Typography variant="h4" gutterBottom>
      Beatbox Guidelines 🎤
      </Typography>
      <Typography variant="body1" gutterBottom>
        When the beatbox community grows, you grow too. Let's keep this space safe, fun, and inspiring!
      </Typography>
      <Divider sx={{ borderColor: '#333', my: 2 }} />
      {guidelines.map((guideline, index) => (
        <Box key={index} sx={{ py: 2 }}>
          <Typography variant="h6" gutterBottom>
            {guideline.title}
          </Typography>
          <Typography variant="body2">
            {guideline.description}
          </Typography>
          
        </Box>
      ))}
      <Divider sx={{ borderColor: '#333', my: 2 }} />
      <Typography variant="h5" gutterBottom>
      Questions or issues? Email us at <a href="mailto:hello@spitbeat.com">hello@spitbeat.com</a>
      </Typography>
    </Box>
  );
};

export default Guidelines;