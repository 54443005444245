import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Alert,
  Typography,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  useTheme,
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import BeatCard from '../components/BeatCard';
import { beats as beatsService, user as userService } from '../services/api';
import { Link, useParams } from 'react-router-dom';
import { Beat, User as UserModel } from '../types';
import SkeletonLoader from '../components/SkeletonLoader';
import { useAuth } from '../context/AuthContext';
import { LogOut, MessageSquareHeart, Moon, Settings, Sun, User } from 'lucide-react';

interface ProfileProps {
  onToggleTheme: () => void;
}

const Profile: React.FC<ProfileProps> = ({onToggleTheme}) => {
  const [beats, setBeats] = useState<Beat[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { username } = useParams<{ username: string }>();
  const [userDetails, setUserDetails] = useState<UserModel>();
  const {user, logout} = useAuth();
  const theme = useTheme();
  const isCurrentUser = user?.username===userDetails?.username;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  

  const fetchBeats = useCallback(async (pageNum: number) => {
    if (loading) return;
    try {
      setLoading(true);
      setError(null);  // Clear any previous errors
      const cleanUsername = username!.startsWith('@') ? username!.slice(1) : username;
      const response = await beatsService.getAll(pageNum, 6, cleanUsername);
      
      setBeats(prev => {
        const newBeats = [...prev];
        response.beats.forEach(beat => {
          if (!newBeats.some(b => b.id === beat.id)) {
            newBeats.push(beat);
          }
        });
        return newBeats;
      });
      
      setHasMore(pageNum < response.pages);
      setPage(pageNum);

    } catch (error) {
      console.error('Error fetching beats:', error);
      setError('Failed to load content. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [username]);

  const loadMore = useCallback(() => {
    if (!loading && hasMore) {
      fetchBeats(page + 1);
    }
  }, [loading, hasMore, page, fetchBeats]);


  const fetchUserDetails = useCallback(async () => {
    if (!username) return;
    try {
      const response = await userService.getUserDetails(username);
      const userDetails = {
        id: response.id,
        username: response.username,
        email:response.email,
        profile_photo: response.profile_photo,
        total_beats: response.total_beats
      };
      setUserDetails(userDetails);
    } catch (error) {
      console.error('Error fetching user details:', error);
      setError('Failed to load user details. Please try again later.');
    }
  }, [username]);

  useEffect(() => {
    setBeats([]);
    fetchUserDetails();
    fetchBeats(1);
  }, [username]);

  if (!beats.length && loading) {
    return (
      <SkeletonLoader length={3} />
    );
  }


  return (
    <Box sx={{ width: '100%', maxWidth: '800px', ml: 0, mr: 'auto', mt:4 }}>
      <Box display="flex" alignItems="center" sx={{ mb: 4,pl:2 }}>
        <img 
          src={userDetails?.profile_photo || undefined} 
          alt={username![0].toUpperCase()} 
          style={{ borderRadius: '50%', width: '50px', height: '50px', marginRight: '10px' }} 
        />
        <Box>
          <Typography variant="h6">{username}</Typography>
          <Typography variant="body2" color="textSecondary">
            {userDetails ? `Total Beats: ${userDetails.total_beats}` : 'Loading beats...'}
          </Typography>
        </Box>
        {isCurrentUser &&(<Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
      <IconButton 
        onClick={handleMenuClick}
        color= 'inherit'
        aria-label="comments"
        sx={{ 
          color: 'text.secondary',
          display: { xs: 'block', md: 'none' },
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.1)'
          }
        }}
      >
        <Settings />
      </IconButton>
      <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          {/* <MenuItem component={Link} to={`/@${user?.username}`} aria-label="Profile" onClick={handleMenuClose}>
            <ListItemIcon>
              <User /> 
              </ListItemIcon>
              My Beats
            </MenuItem>
            <Divider/> */}
            <MenuItem component={Link} to="/guidelines" aria-label="Guidelines" onClick={handleMenuClose}>
            <ListItemIcon>
              <MessageSquareHeart /> 
              </ListItemIcon>
              Guidelines
            </MenuItem>
            
            <MenuItem onClick={onToggleTheme} aria-label="Toggle theme">
              {theme.palette.mode === 'dark' ? (
                <>
                <ListItemIcon>
                  <Moon />
                  </ListItemIcon>
                  Light Mode
                </>
              ) : (
                <>
                <ListItemIcon>
                  <Sun  />
                  </ListItemIcon>
                  Dark Mode
                </>
              )}
            </MenuItem>
            <Divider />
           <MenuItem onClick={() => { logout(); handleMenuClose(); }} aria-label="Logout" >
             <ListItemIcon>
               <LogOut />
             </ListItemIcon>
             Logout
           </MenuItem>
        </Menu>
    </Box>)}
      </Box>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

<InfiniteScroll
      endMessage={
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
          <Typography variant="body2" color="textSecondary" marginBottom={15}>
            {isCurrentUser && (<>When you share a recording, it will appear on your profile and be publicly visible.</>)}
            {!isCurrentUser &&(<>No more beats to show!</>)}
          </Typography>
        </Box>
      }
        dataLength={beats.length}
        next={loadMore}
        hasMore={hasMore}
        loader={
          <SkeletonLoader length={3} />
        }
        style={{ overflow: 'visible' }}
      >
        {beats.map(beat => (
          <React.Fragment key={`beat-wrapper-${beat.id}`}>
          <BeatCard
            key={`beat-${beat.id}`}
            beat={beat}
          />
         <Divider sx={{ my:2 }} />
         </React.Fragment>
        ))}
      </InfiniteScroll>
    </Box>
  );
};

export default React.memo(Profile);