import React from 'react';
import { Box, Stack, Skeleton } from '@mui/material';

interface SkeletonLoaderProps {
    length: number; // Add a prop type for length
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({ length }) => {
    return (
        <>
            {Array.from({ length }).map((_, index) => (
                <Box key={index} display="flex" justifyContent="center" alignItems="center" minHeight="200px" maxWidth='800px' padding={2}>
                    <Stack spacing={1} width="100%">
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="rectangular" width='100%' height={80} />
                        <Skeleton variant="rounded" width='100%' height={60} />
                    </Stack>
                </Box>
            ))}
        </>
    );
};

export default SkeletonLoader;