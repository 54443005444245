import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  useTheme,
} from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { GoogleLogin } from '@react-oauth/google';
import { CirclePlay, Info, MicVocal } from 'lucide-react';

const LandingPage: React.FC<{ inviteText?: string[] }> = ({ inviteText }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAuthenticated, googleLogin } = useAuth();
  const [error, setError] = React.useState<string | null>(null);

  const handleGoogleSuccess = async (credentialResponse: any) => {
    try {
      await googleLogin(credentialResponse);
      navigate('/');
    } catch (error) {
      console.error('Google sign-in error:', error);
    }
  };
  
  const handleGoogleError = () => {
    setError('Google sign-in failed');
  };

  const handleGetStarted = () => {
    navigate('/');
  };

  return (
    <Box maxWidth="md" sx={{ 
      mx: 'auto', 
      p: 2,
      minHeight: '60vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      mb: 4,
      color: theme.palette.text.primary,
      transition: 'color 0.3s ease'
    }}>
      {/* New Title */}
      <Typography 
        variant="h6" 
        component="div" 
        className="green-text"
        sx={{ 
          position: 'absolute', 
          top: 16, 
          left: 16, 
          color: theme.palette.text.primary,
          fontFamily: 'Bebas Neue, sans-serif',
          display: { xs: 'none', md: 'block' },
        }}
      >
        spitbeat
      </Typography>
      {/* Hero Section */}
      <Box sx={{ 
        p: 4, 
        maxWidth: 800,
        color: theme.palette.text.primary
      }}>
        <Typography 
          variant="h1" 
          component="h1" 
          sx={{ 
            fontSize: { xs: '2.5rem', md: '5rem' },
            fontWeight: 700,
            lineHeight: 1,
            mb: 1,
            '& .green-text': {
              display: 'block',
              background: 'linear-gradient(135deg, #4CAF50 0%, #45a049 20%, #2E7D32 40%, #1B5E20 60%, #45a049 80%, #4CAF50 100%)',
              backgroundSize: '200% auto',
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              color: 'transparent',
              animation: 'gradient 3s ease infinite',
              mb: 0.5
            },
            '& .white-text': {
              display: 'block',
              color: theme.palette.text.primary,
              fontSize: { xs: '2.5rem', md: '5rem' },
              mb: 0.5
            },
            '@keyframes gradient': {
              '0%': {
                backgroundPosition: '0% 50%'
              },
              '50%': {
                backgroundPosition: '100% 50%'
              },
              '100%': {
                backgroundPosition: '0% 50%'
              }
            }
          }}
        >
          <span className="green-text">{inviteText && inviteText[0] ? inviteText[0] : 'Your beats,'}</span>
          <span className="white-text">{inviteText && inviteText[1] ? inviteText[1] : 'your stage,'}</span>
          <span className="white-text">{inviteText && inviteText[2] ? inviteText[2] : 'no limits.'}</span>
        </Typography>
        <Typography 
          sx={{ 
            fontSize: '1.25rem',
            my: 4,
            opacity: 0.9,
            maxWidth: 600,
            mx: 'auto',
            color: theme.palette.text.secondary
          }}
        >
          Spitbeat: The ultimate platform for beatboxers to battle, share, and connect. Ready to drop your first beat? Let's go.
        </Typography>

        {/* Call to Action */}
        {isAuthenticated ? (
          <><Button
          fullWidth
          variant="contained"
          size="large"
          onClick={() => navigate('/record')}
          startIcon={<MicVocal />}
          sx={{ 
            mt: 4,
            borderRadius: 20,
            py: 2,
            px: 3,
            bgcolor: theme.palette.primary.main,
            backdropFilter: 'blur(10px)',
            color: 'white',
            fontWeight:'bold',
            textTransform: 'none',
            fontSize: '1.1rem',
            '&:hover': {
              bgcolor: theme.palette.primary.dark,
            }
          }}
        >
          Record your beatbox
        </Button>
        <Button
          startIcon={<CirclePlay />}
          variant="contained"
          size="large"
          onClick={() => navigate('/explore/recent')}
          sx={{ 
            m:2,
            borderRadius: 20,
            py: 2,
            px: 3,
            bgcolor: 'rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(10px)',
            color: theme.palette.text.primary,
            textTransform: 'none',
            fontSize: '1.1rem',
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.15)',
            }
          }}
        >
          See whats trending
        </Button>
        <Button
          startIcon={<Info/>}
          variant="contained"
          size="small"
          onClick={() => navigate('/guidelines')}
          sx={{ 
            m: 2,
            borderRadius: 20,
            py: 2,
            px: 3,
            bgcolor: 'rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(10px)',
            color: theme.palette.text.primary,
            textTransform: 'none',
            fontSize: '1.1rem',
            '&:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.15)',
            }
          }}
        >
          Read the Guidelines
        </Button></>
        ) : (
          <>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, gap: 2, alignItems: 'center', flexDirection: 'column' }}>
            <GoogleLogin
              text='continue_with' 
              onSuccess={handleGoogleSuccess}
              onError={handleGoogleError}
            />
 
            {/* <Button
              variant="outlined"
              startIcon={<img src="https://github.com/favicon.ico" alt="GitHub" style={{ width: 16, height: 16 }} />}
              onClick={() => window.open('https://github.com/shasha98sr/beatexchange', '_blank')}
              sx={{
                backgroundColor: theme.palette.grey[50],
                color: 'black',
                borderColor: theme.palette.divider,
                borderRadius: 1,
                '&:hover': {
                  backgroundColor: theme.palette.grey[100],
                  borderColor: '#fff',
                }
              }}
            >
              Star on GitHub
            </Button> */}
          </Box>
            
           {error && (
              <Typography color="error" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
            </>
        )}
      </Box>
    </Box>
  );
};

export default LandingPage;
