import React, { useState } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  useTheme,
  Box,
} from '@mui/material';

interface NavbarProps {
  onToggleTheme: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onToggleTheme }) => {
  const theme = useTheme();

  return (
    <AppBar
      position="static"
      sx={{
        background: theme.palette.background.default,
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)'}`,
        transition: 'background-color 0.3s ease, border-color 0.3s ease'
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ flexGrow: 1 }} />
        <Typography
          variant="h6"
          component={RouterLink}
          to="/"
          className="gradient-text"
          sx={{ 
            margin: 'auto', 
            fontFamily: 'Bebas Neue, sans-serif'   
          }}
        >
          spitbeat
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
