import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import BeatCard from '../components/BeatCard';
import { beats as beatsService } from '../services/api';
import { Beat, Comment } from '../types';
import { useAuth } from '../context/AuthContext';
import LandingPage from './LandingPage';
import { ChevronLeft } from 'lucide-react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import SkeletonLoader from '../components/SkeletonLoader';
import CommentItem from '../components/CommentItem';
import { Helmet } from 'react-helmet';
// import { Link } from 'react-router-dom';
// import { Avatar, Stack } from '@mui/material';
// import { useTheme } from '@mui/material/styles';

const BeatPage: React.FC = () => {
  const { username ,beatId } = useParams<{ username: string, beatId: string }>();
  const { isAuthenticated } = useAuth();
  const [beat, setBeat] = useState<Beat | null>(null);
  const [comments, setComments] = useState<Comment[]>([]);
  const theme = useTheme();

  const handleDeleteComment = (commentId: number) => {
    setComments((prevComments) => prevComments.filter(comment => comment.id !== commentId));
  };
  const handleAddComment = (comment: Comment) => {
    setComments((prevComments) => [comment, ...prevComments]);
  };
  useEffect(() => {
    // Fetch the beat data by beatId
    const fetchBeat = async () => {
      if (username && beatId) {
        const response = await beatsService.getSingle(username, beatId);
        setBeat(response);
        setComments(response.comments);
      }
    };
  if (isAuthenticated) fetchBeat();
  }, [beatId,isAuthenticated, username ]);


  if (!isAuthenticated) {
    return (
      <LandingPage inviteText={[`${username}`, 'dropped a beat,', 'log in to listen.']} />
    );
  }
  if (!beat) return SkeletonLoader({ length: 1 });
  return (
    <>
    <Helmet>
        <meta property="og:title" content={beat.title} />
        <meta property="og:description" content={beat.description} />
        <meta property="og:image" content={beat.author_photo} />
        <meta property="og:audio" content={`${beat.audio_url}`} />
        <meta property="og:audio:type" content="audio/mpeg" />
      </Helmet>
    <div className="container">
      <Stack direction="row" spacing={1} alignItems="center" marginBottom={2}>
      <Link to={`/`} className="items-center">
        <ChevronLeft className="ml-4" size={24} /> 
      </Link>
      <Typography variant="h6">
      Beat
      </Typography>
      </Stack>
      <BeatCard beat={beat} showCommentsBox = {true} handleNewCommentCallback={handleAddComment}/>
      <Box sx={{ width: '100%', 
      padding:'20px', 
      borderRadius: 0, 
      backgroundColor: theme.palette.background.default,
      border: 'none',}}>
      <h4 className="">Comments:</h4>
      {comments.length > 0 ? (
        <ul className="">
          {comments.map((comment) => (
            <CommentItem
            key={comment.id}
            comment={comment}
            onDeleteCallback={handleDeleteComment}
          />       
          ))}
        </ul>
      ) : (
        <p className="">No comments available.</p>
      )}
      </Box>
    </div>
    </>
  );
};



export default BeatPage;
