import React, { useEffect, useState, useCallback } from 'react';
import {
  Box,
  Alert,
  Typography,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import BeatCard from '../components/BeatCard';
import { beats as beatsService } from '../services/api';
import { Beat } from '../types';
import SkeletonLoader from '../components/SkeletonLoader';
import { useNavigate } from 'react-router-dom';


const RecentFeed: React.FC = React.memo(() => {
  const [beats, setBeats] = useState<Beat[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [alignment, setAlignment] = React.useState('recent');
  const navigate = useNavigate();

  const fetchBeats = useCallback(async (pageNum: number) => {
    if (loading) return;
    try {
      setLoading(true);
      setError(null);  // Clear any previous errors
      const response = await beatsService.getAll(pageNum, 4);
      
      setBeats(prev => {
        const newBeats = [...prev];
        response.beats.forEach(beat => {
          if (!newBeats.some(b => b.id === beat.id)) {
            newBeats.push(beat);
          }
        });
        return newBeats;
      });
      
      setHasMore(pageNum < response.pages);
      setPage(pageNum);
    } catch (error) {
      console.error('Error fetching beats:', error);
      setError('Failed to load content. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  const loadMore = useCallback(() => {
    if (!loading && hasMore) {
      fetchBeats(page + 1);
    }
  }, [loading, hasMore, page, fetchBeats]);
  

  useEffect(() => {
    fetchBeats(1);
  }, []);

  if (!beats.length && loading) {
    return (
      <SkeletonLoader length={4} />
    );
  }

  const handleToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    if (newAlignment === 'top') {
      navigate('/explore/top');
    }
    // setAlignment(newAlignment);
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '800px', ml: 0, mr: 'auto' }}>
      
        <Box sx={{ width: '100%', mb: 2 }}>
          <ToggleButtonGroup
            value={alignment}
            exclusive
            onChange={handleToggleChange}
            aria-label="feed view"
            size="small"
            sx={{
              width: '100%',
              '& .MuiToggleButton-root': {
                flex: 1,
                border: 'none',
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: 0,
                display: { xs: 'block', md: 'none' },
                '&.Mui-selected': {
                  borderBottom: '2px solid green',
                },
              }
            }}
          >
            <ToggleButton value="recent">Recent</ToggleButton>
            <ToggleButton value="top">Top</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      <Typography variant="h6" sx={{ mb: 2,pl:2,  }}>
       Recent Beats
      </Typography>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <InfiniteScroll
      endMessage={
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2, }}>
          <Typography variant="body2" color="textSecondary" marginBottom={15}>
            No more beats to load!
          </Typography>
        </Box>
      }
        dataLength={beats.length}
        next={loadMore}
        hasMore={hasMore}
        loader={
            <SkeletonLoader length={4} />
        }
        style={{ overflow: 'visible' ,height: '100vh' }} // Ensure the height is set
        scrollThreshold={0.8} // Trigger load more when 9
      >
        {beats.map(beat => (
          <React.Fragment key={`beat-wrapper-${beat.id}`}>
          <BeatCard
            key={`beat-${beat.id}`}
            beat={beat}
          />
         <Divider sx={{ my:2 }} />
         </React.Fragment>
        ))}
      </InfiniteScroll>
    </Box>
  );
});


export default React.memo(RecentFeed);