import axios from 'axios';
import { Beat, User, Comment, PaginatedBeatsResponse } from '../types';

const BASE_URL = process.env.REACT_APP_API_URL;
const API_URL = `${BASE_URL}/api`;

// Create two axios instances: one for JSON and one for multipart
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const uploadApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

// Add token to requests if available
const addAuthHeader = (config: any) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
};

api.interceptors.request.use(addAuthHeader);
uploadApi.interceptors.request.use(addAuthHeader);

export const auth = {
  login: async (email: string, password: string): Promise<{ token: string; user: User }> => {
    try {
      const response = await api.post('/auth/login', { email, password });
      return response.data;
    } catch (error) {
      console.error('API error logging in:', error);
      throw error;
    }
  },
  register: async (username: string, email: string, password: string): Promise<{ token: string; user: User }> => {
    try {
      const response = await api.post('/auth/register', { username, email, password });
      return response.data;
    } catch (error) {
      console.error('API error registering:', error);
      throw error;
    }
  },
  googleLogin: async (credential: string): Promise<{ token: string; user: User }> => {
    try {
      const response = await api.post('/auth/google', { credential });
      return response.data;
    } catch (error) {
      console.error('API error logging in with Google:', error);
      throw error;
    }
  },
  getCurrentUser: async (): Promise<User> => {
    try {
      const response = await api.get('/auth/me');
      return response.data;
    } catch (error) {
      console.error('API error getting current user:', error);
      throw error;
    }
  },
};

export const beats = {
  getAll: async (page = 1, perPage = 10, username = ''): Promise<PaginatedBeatsResponse> => {
    try {
      // Check if username is provided and include it in the request
      const params = new URLSearchParams({
        page: page.toString(),
        per_page: perPage.toString(),
        ...(username && { username }) // Conditionally add username only if it exists
      });
  
      const response = await api.get(`/beats?${params.toString()}`);
      return response.data;
    } catch (error) {
      console.error('API error getting all beats:', error);
      throw error;
    }
  },
  getTop: async (page = 1, perPage = 10): Promise<PaginatedBeatsResponse> => {
    try {
      const params = new URLSearchParams({
        page: page.toString(),
        per_page: perPage.toString(),
      });

      const response = await api.get(`/top-beats?${params.toString()}`);
      return response.data;
    } catch (error) {
      console.error('API error getting top beats:', error);
      throw error;
    }
  },
  getSingle: async (username:string, beatId: string,): Promise<Beat> => {
    try {
      const cleanUsername = username!.startsWith('@') ? username!.slice(1) : username;
      const response = await api.get(`/beats/${beatId}?username=${cleanUsername}`);
      return response.data;
    } catch (error) {
      console.error('API error getting single beat:', error);
      throw error;
    }
  },
  upload: async (formData: FormData): Promise<Beat> => {
    try {
      const response = await uploadApi.post('/beats', formData);
      return response.data;
    } catch (error) {
      console.error('API error uploading beat:', error);
      throw error;
    }
  },
  like: async (beatId: number): Promise<Beat> => {
    try {
      const response = await api.post(`/beats/${beatId}/like`);
      return response.data;
    } catch (error) {
      console.error('API error liking beat:', error);
      throw error;
    }
  },
  comment: async (beatId: number, content: string): Promise<Comment> => {
    try {
      const response = await api.post(`/beats/${beatId}/comments`, { content });
      return response.data;
    } catch (error) {
      console.error('API error commenting on beat:', error);
      throw error;
    }
  },
  getComments: async (beatId: number): Promise<Comment[]> => {
    try {
      const response = await api.get(`/beats/${beatId}/comments`);
      return response.data;
    } catch (error) {
      console.error('API error getting comments for beat:', error);
      throw error;
    }
  },
  addComment: async (beatId: number, content: string, timestamp: number): Promise<Comment> => {
    try {
      const response = await api.post(`/beats/${beatId}/comments`, { content, timestamp });
      return response.data;
    } catch (error) {
      console.error('API error adding comment to beat:', error);
      throw error;
    }
  },
  deleteComment: async (commentId: number): Promise<void> => {
    try {
      await api.delete(`/comments/${commentId}`);
    } catch (error) {
      console.error('API error deleting comment from beat:', error);
      throw error;
    }
  }
};

export const user = {
  getUserDetails: async (username: string): Promise<User> => {
    try {
      const cleanUsername = username!.startsWith('@') ? username!.slice(1) : username;
      const response = await api.get(`/user?username=${cleanUsername}`);
      return response.data;
    } catch (error) {
      console.error('API error getting user details:', error);
      throw error;
    }
  },
};

export default api;
