import React from 'react';
import AudioPlayer from './AudioPlayer';
import Maudioplayer from './mobile/MAudioPlayer';
import { Beat, Comment } from '../types';

// Utility function to detect if the screen width is small (e.g., mobile)
const isSmallScreen = () => {
  return window.innerWidth <= 768; // Example threshold for small screens
};

interface BeatCardProps {
  beat: Beat;
  showCommentsBox?: boolean,
  handleNewCommentCallback?: (comment: Comment) => void
}

const BeatCard: React.FC<BeatCardProps> = React.memo(({ beat, showCommentsBox, handleNewCommentCallback }) => {
  const PlayerComponent = isSmallScreen() ? Maudioplayer : AudioPlayer; // Choose component based on screen size

  return (
    <div 
      className="w-full hover:bg-card/90 transition-colors"
      style={{
        willChange: 'transform',
        transform: 'translateZ(0)'
      }}
    >
      <PlayerComponent 
        beat={beat}
        showCommentsBox={showCommentsBox}
        addnewCommentCallback={handleNewCommentCallback}
      /> 
    </div>
  );
});

BeatCard.displayName = 'BeatCard';

export default BeatCard;
