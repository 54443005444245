import React, { useEffect, useRef, useState, useCallback } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { Box, IconButton, Typography, TextField, Button, Stack, Alert, useTheme, Avatar, CircularProgress, Menu, MenuItem, ListItemIcon } from '@mui/material';
import { PlayArrow, Pause, Send } from '@mui/icons-material';
import { useAuth } from '../../context/AuthContext';
import { beats } from '../../services/api';
import { Link2, MessageCircle, Send as SendLC } from "lucide-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import TimeAgo from 'react-timeago';

import { Beat, Comment } from '../../types';
import { Link } from 'react-router-dom';

interface AudioPlayerProps {
  beat: Beat;
  showCommentsBox?: boolean;
  addnewCommentCallback?: (comment: Comment) => void;
}


interface LikeResponse {
  message: string;
}

const LikeButton = ({ isLiked, likeCount, onLikeToggle}: { isLiked: boolean; likeCount: number; onLikeToggle: () => void }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
      <IconButton 
        onClick={onLikeToggle} 
        color="primary" 
        aria-label="like"
        sx={{ 
          color: isLiked ? '#ff8c00' : 'text.secondary', // Changed to orange color
          transition: 'all 0.2s ease',
          '&:hover': {
            backgroundColor: isLiked 
              ? 'rgba(255,140,0,0.1)' // Changed to orange background
              : 'rgba(0,0,0,0.1)',
            color: isLiked ? '#ff9900' : '#ff8c00' // Changed to orange color
          }
        }}
      >
        <FontAwesomeIcon 
          icon={faFire} 
          size="sm"
          style={{ 
            color: isLiked ? '#ff4500' : 'grey',
            fill: isLiked ? 'currentColor' : 'none', 
            strokeWidth: isLiked ? 1 : 2 
          }}
        />
      </IconButton>
      <Typography 
        variant="body2" 
        sx={{ 
          color: isLiked ? '#ff8c00' : 'text.secondary', // Changed to orange color
          fontWeight: isLiked ? 'bold' : 'normal',
          transition: 'color 0.2s ease'
        }}
      >
        {likeCount}
      </Typography>
    </Box>
  );
};

const CommentButton = ({ commentCount, onClick }: { commentCount: number; onClick: () => void }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0 }}>
      <IconButton 
        onClick={onClick}
        color= 'inherit'
        aria-label="comments"
        sx={{ 
          color: 'text.secondary',
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.1)'
          }
        }}
      >
        <MessageCircle />
      </IconButton>
      <Typography variant="body2" sx={{ color: 'text.secondary', fontWeight: 'normal' }}>
        {commentCount}
      </Typography>
    </Box>
  );
};


const MAudioPlayer: React.FC<AudioPlayerProps> = ({
  beat,
  showCommentsBox,
  addnewCommentCallback
}) => {
  const { token } = useAuth();
  const theme = useTheme();
  const waveformRef = useRef<HTMLDivElement>(null);
  const wavesurfer = useRef<WaveSurfer | null>(null);
  const errorTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const [comments, setComments] = useState<Comment[]>(beat.comments);
  const [newComment, setNewComment] = useState('');
  const [isDestroyed, setIsDestroyed] = useState(false);
  const [showComments, setShowComments] = useState(showCommentsBox || false); 
  const [isLoading, setIsLoading] = useState(true);
  const [isLiked, setIsLiked] = useState(beat.liked_by_user);
  const [likeCount, setLikeCount] = useState(beat.likes_count);
  const isMobile = window.innerWidth <= 768;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [newCommentTime, setNewCommentTime] = useState<number>(0);
  const [showMarker, setShowMarker] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate(); 

  const commentMarkerRadiusDesktop = '19px';
  const commentMarkerRadiusMobile = '17px';

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const setErrorWithDelay = useCallback((errorMessage: string | null) => {
    if (errorTimeoutRef.current) {
      clearTimeout(errorTimeoutRef.current);
      errorTimeoutRef.current = null;
    }
    
    if (errorMessage === null) {
      setError(null);
      return;
    }

    errorTimeoutRef.current = setTimeout(() => {
      if (!isDestroyed) {
        setError(errorMessage);
      }
    }, 1500); // 1 second delay before showing error
  }, [isDestroyed]);
  //need to optimize this, calling the api every time the audio player is rendered is not good
  useEffect(() => {
    if (!beat || !token) {
      setErrorWithDelay('Please sign in to play audio');
      setIsLoading(false);
      return;
    }

    const initializeAudio = async () => {
      try {
        setIsLoading(true);
        setErrorWithDelay(null);
        setIsDestroyed(false);
        
        const audioUrlToUse = beat.audio_url

        if (waveformRef.current && !wavesurfer.current) {
          // Create canvas for gradient
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          if (!ctx) return;

          // Set canvas height to match desired waveform height
          canvas.height =  100;
          const gradientPercent = parseInt(isMobile? commentMarkerRadiusMobile : commentMarkerRadiusDesktop)/canvas.height ;

          // Create gradient for non-progress region
          const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height * 1.7);
          gradient.addColorStop(0, theme.palette.secondary.light);
          // gradient.addColorStop(1- gradientPercent, theme.palette.secondary.light);
          // gradient.addColorStop(1, theme.palette.background.paper);

          // Create gradient for progress region
          const progressGradient = ctx.createLinearGradient(0, 0, 0, canvas.height * 1.7);
          progressGradient.addColorStop(0, theme.palette.primary.main);
          // progressGradient.addColorStop(1- gradientPercent, theme.palette.secondary.main);
          // progressGradient.addColorStop(1, theme.palette.background.paper);

          const ws = WaveSurfer.create({
            container: waveformRef.current,
            waveColor: gradient,
            progressColor: progressGradient,
            cursorColor: theme.palette.primary.main,
            cursorWidth: 1,
            barWidth: 4,
            // barGap: isMobile? 2 : 2,
            height: isMobile? 70 : 100,
            barRadius: 3,
            normalize: true,
            interact: true,
            fillParent: true
          });

          wavesurfer.current = ws;

          ws.on('play', () => setIsPlaying(true));
          ws.on('pause', () => setIsPlaying(false));
          ws.on('ready', () => {
            if (!isDestroyed) {
              setDuration(ws.getDuration());
              updateCommentMarkers();
              setErrorWithDelay(null);
              setIsLoading(false);
            }
          });
          ws.on('audioprocess', () => {
            const time = ws.getCurrentTime();
            setCurrentTime(time);
            
            // Find the comment closest to current time within the visibility window
            let closestComment: Comment | null = null;
            let minTimeDiff = 1; // Maximum time difference to show a comment

            comments.forEach(comment => {
              const timeDiff = Math.abs(comment.timestamp - time);
              if (timeDiff <= minTimeDiff && (!closestComment || timeDiff < Math.abs(closestComment.timestamp - time))) {
                closestComment = comment;
              }
            });

            // Update comment visibility
            const commentElements = commentMarkersRef.current?.querySelectorAll('.comment-bubble');
            commentElements?.forEach(element => {
              const htmlElement = element as HTMLDivElement;
              const commentId = htmlElement.dataset.commentId;
              const isVisible = closestComment && commentId === closestComment.id.toString();
              
              // Smooth transition
              htmlElement.style.transition = 'opacity 0.5s ease-in-out';
              htmlElement.style.opacity = isVisible ? '1' : '0';
              htmlElement.style.transform = isVisible ? 'none' : 'none';
            });
          });
          ws.on('timeupdate', () => {
            setCurrentTime(ws.getCurrentTime());
          });
          ws.on('error', (error) => {
            console.error('WaveSurfer error:', error);
            if (!isDestroyed) {
              setErrorWithDelay('Error loading audio. Please try again later.');
              setIsLoading(false);
            }
          });

          try {
            await ws.load(audioUrlToUse);
          } catch (error) {
            console.error('Error loading audio:', error);
            if (!isDestroyed) {
              setErrorWithDelay('Error loading audio. Please try again later.');
              setIsLoading(false);
            }
          }
        }
      } catch (err) {
        console.error('Error initializing audio:', err);
        if (!isDestroyed) {
          setErrorWithDelay('Error loading audio');
          setIsLoading(false);
        }
      }
    };

    initializeAudio();

    return () => {
      setIsDestroyed(true);
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
      if (wavesurfer.current) {
        wavesurfer.current.unAll();
        wavesurfer.current.destroy();
        wavesurfer.current = null;
      }
    };
  }, [beat, isDestroyed, token, setErrorWithDelay, comments, isMobile, theme.palette.background.default, theme.palette.primary.main, theme.palette.secondary.light, theme.palette.secondary.main]);

  const handlePlayPause = () => {
    if (!wavesurfer.current) return;
    
    if (isPlaying) {
      wavesurfer.current.pause();
    } else {
      wavesurfer.current.play();
      if (!isMobile) { // Only show comments if not on mobile
        setShowComments(true);
      }
    }
    setIsPlaying(!isPlaying);
  };

  const updateCommentMarkers = useCallback(() => {
    if (!commentMarkersRef.current || !wavesurfer.current || !comments.length) return;

    // Clear existing markers
    commentMarkersRef.current.innerHTML = '';

    const duration = wavesurfer.current.getDuration();

    // Group comments by rounded timestamp and keep only the oldest
    const commentsByTimestamp = comments.reduce((acc, comment) => {
      const roundedTimestamp = Math.round(comment.timestamp * 2) / 2;
      const existingComment = acc.get(roundedTimestamp);
      if (!existingComment || new Date(comment.created_at) < new Date(existingComment.created_at)) {
        acc.set(roundedTimestamp, comment);
      }
      return acc;
    }, new Map());

    // Create markers only for the oldest comments
    Array.from(commentsByTimestamp.values()).forEach(comment => {
      const marker = document.createElement('div');
      const position = (comment.timestamp / duration) * 100;
      
      marker.style.position = 'absolute';
      marker.style.left = `${position}%`;
      marker.style.bottom = '0';
      marker.style.width = isMobile? commentMarkerRadiusMobile: commentMarkerRadiusDesktop;
      marker.style.height = isMobile? commentMarkerRadiusMobile: commentMarkerRadiusDesktop;
      marker.style.borderRadius = '50%';
      marker.style.backgroundColor = theme.palette.background.default;
      marker.style.opacity = showMarker ? '0.5' : '1';
      marker.style.pointerEvents = 'auto';  // Enable pointer events for hover
      marker.style.cursor = 'pointer';      // Show pointer cursor on hover
      marker.style.zIndex = '1';
      marker.innerHTML = `<img src="${comment.user_photo}" alt="i" style="width:${isMobile ?commentMarkerRadiusMobile: commentMarkerRadiusDesktop}; height: ${isMobile ? commentMarkerRadiusMobile : commentMarkerRadiusDesktop}; border-radius: 50%; border: 1px solid ${theme.palette.background.default};"/>`;
      const commentElement = document.createElement('div');
      commentElement.className = 'comment-bubble';
      commentElement.style.position = 'absolute';
      if (isMobile) {
        commentElement.style.left = '0'; // Always display on the left for mobile
        commentElement.style.right = 'auto';
      } else if (position > 50) {
        commentElement.style.right = `${100 - position}%`;
        commentElement.style.left = 'auto';
      } else {
        commentElement.style.left = `calc(${position}%)`;
        commentElement.style.right = 'auto';
      }
      commentElement.style.top = '100%';
      commentElement.style.transform = 'none';
      commentElement.style.padding = '0 0 4px 0';
      commentElement.style.borderRadius = '4px';
      commentElement.style.whiteSpace = 'nowrap';
      commentElement.style.opacity = '0';
      commentElement.style.transition = 'all 0.3s ease-in-out';
      commentElement.style.pointerEvents = 'none';
      commentElement.style.zIndex = '2';
      commentElement.style.marginTop = '1px';
      commentElement.innerHTML = isMobile || position <= 50
        ? `<span style="color: #1db954">${comment.username} </span><span style="color: ${theme.palette.text.primary}">${comment.content}</span>`
        : `<span style="color: ${theme.palette.text.primary}">${comment.content}</span> <span style="color: #1db954">${comment.username}</span>`;
      
      marker.dataset.commentId = comment.id.toString();
      commentElement.dataset.commentId = comment.id.toString();

      // Add hover events
      marker.addEventListener('mouseenter', () => {
        commentElement.style.opacity = '1';
      });

      marker.addEventListener('mouseleave', () => {
        commentElement.style.opacity = '0';
      });

      if (position > 50) {
        commentMarkersRef.current?.appendChild(commentElement);
        commentMarkersRef.current?.appendChild(marker);
      } else {
        commentMarkersRef.current?.appendChild(marker);
        commentMarkersRef.current?.appendChild(commentElement);
      }
    });
  }, [comments, theme, isMobile]);

  useEffect(() => {
    if (!comments.length || !wavesurfer.current) return;
    updateCommentMarkers();
  }, [comments, updateCommentMarkers,showMarker]);

  const handleAddComment = async () => {
    if (!newComment.trim()) return;

    try {
      if (!token) {
        setErrorWithDelay('Please log in to comment');
        return;
      }

      const response = await beats.addComment(beat.id, newComment, newCommentTime!);

      const newCommentObj: Comment = {
        id: response.id,
        content: response.content,
        timestamp: response.timestamp,
        username: response.username,
        user_photo: response.user_photo,
        created_at: response.created_at
      };

      setComments(prev => [...prev, newCommentObj]);
      addnewCommentCallback?.(newCommentObj);
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment:', error);
      setErrorWithDelay('Failed to add comment');
    }
  };

  const handleLikeToggle = async () => {
    try {
      if (!token) {
        setErrorWithDelay('Please log in to like');
        return;
      }

      const response = await beats.like(beat.id) as unknown as LikeResponse;
      setIsLiked(response.message === 'Beat liked');
      setLikeCount(response.message === 'Beat liked' ? likeCount + 1 : likeCount - 1);
      // onLike?.(beatId);
    } catch (error) {
      console.error('Error liking beat:', error);
      setErrorWithDelay('Failed to like beat');
    }
  };

  const currentUrl = `${window.location.protocol}//${window.location.host}`; // Get the current URL base

  const handleShareClick = () => {
    
    const shareLink = `${currentUrl}/@${beat.author}/${beat.id}`; // Construct the full share link
    navigator.clipboard.writeText(shareLink);
    handleMenuClose(); // Copy the link to clipboard
  };
  const handleMenuClose = () => {
    setAnchorEl(null); // Close the menu
};
const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
  setAnchorEl(event.currentTarget);// Close the menu
};

  const commentMarkersRef = useRef<HTMLDivElement>(null);

  const customFormatter = (value: number, unit: string, suffix: string, epochMilliseconds: number) => {
    switch (unit) {
      case 'second':
        return `${value}s`;
      case 'minute':
        return `${value}m`;
      case 'hour':
        return `${value}h`;
      case 'day':
        return `${value}d`;
      case 'week':
        return `${value}w`;
      case 'month':
        return `${value}mo`;
      case 'year':
        return `${value}y`;
      default:
        return `${value}${unit.charAt(0)}`;
    }
  };

  return (
    <Box sx={{ 
      width: '100%', 
      padding: isMobile ? '10px' : '20px', 
      borderRadius: 0, 
      backgroundColor: theme.palette.background.paper,
      border:'none',
    }}>
      {error && !isLoading && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          {error}
        </Alert>
      )}
      <Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%', justifyContent: 'space-between' }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Link to={`/@${beat.author}`} style={{ textDecoration: 'none' }}>
            <Avatar 
              src={beat.author_photo || undefined} 
              alt={beat.author}
              sx={{ 
                width: 40, 
                height: 40,
                bgcolor: theme.palette.primary.main
              }}
            >
              {beat.author[0].toUpperCase()}
            </Avatar>
          </Link>
          <Stack spacing={0} alignItems="left">
            <Link to={`/@${beat.author}`} style={{ textDecoration: 'none' }}>
              <Typography variant="body2" sx={{ color: theme.palette.text.secondary, mt: -0.5 }}>
                {'@' + beat.author + ' • '} <TimeAgo date={new Date(beat.created_at + 'Z')} formatter={customFormatter} />
              </Typography>
            </Link>
            <Link to={`/@${beat.author}/${beat.id}`} style={{ textDecoration: 'none' }}>
                <Typography variant={isMobile? 'body2':'h6'} sx={{ color: theme.palette.text.primary }}>
                  {beat.title}
                </Typography>
            </Link>
          </Stack>
        </Stack>
      </Stack>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, width: '100%', marginY: 2 }}>
   
      <IconButton 
          onClick={handlePlayPause} 
          disabled={isLoading}
          sx={{
            color: theme.palette.background.paper,
            borderRadius: '50%',
            position: 'relative',
            background: theme.palette.primary.main,
            '&.Mui-disabled': {
              borderColor: theme.palette.primary.main,
              color: theme.palette.primary.main,
            }
          }}
        >
          {isLoading ? (
            <CircularProgress
              size={24}
              sx={{
                color: theme.palette.primary.main,
                position: 'absolute',
              }}
            />
          ) : isPlaying ? (
            <Pause />
          ) : (
            <PlayArrow />
          )}
        </IconButton>
        <Box sx={{ position: 'relative', width: '100%' }}>
        <Typography 
            variant="body2" 
            sx={{ position: 'absolute', left: 0,bottom: '50%', color: theme.palette.primary.main, zIndex: 20, backgroundColor: theme.palette.background.paper }}
          >
            {formatTime(currentTime)}
          </Typography>
          <Typography 
            variant="body2" 
            sx={{ position: 'absolute', right: 0,bottom: '50%', color: theme.palette.primary.main, zIndex: 20, backgroundColor: theme.palette.background.paper }}
          >
            {formatTime(duration)}
          </Typography>
          <Box 
            ref={waveformRef} 
            sx={{ 
              width: '100%',
              position: 'relative',
              cursor: 'pointer',
              '& wave': {
                overflow: 'hidden'
              },
              '&:hover .hover-effect': {
                opacity: 1
              }
            }} 
          >
            <Box
              className="hover-effect"
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                zIndex: 10,
                pointerEvents: 'none',
                height: '100%',
                width: '100%',
                mixBlendMode: 'overlay',
                bgcolor: 'rgba(255, 255, 255, 0.5)',
                opacity: 0,
                transition: 'opacity 0.2s ease'
              }}
            />
          </Box>
          {/* // Rendering the temporary marker */}
{(showMarker ||newComment.trim()) && (
  <div
    style={{
      position: 'absolute',
      left: `${(newCommentTime / duration) * 100}%`,
      bottom: '0',
      width: isMobile ? commentMarkerRadiusMobile : commentMarkerRadiusDesktop,
      height: isMobile ? commentMarkerRadiusMobile : commentMarkerRadiusDesktop,
      borderRadius: '30px',
      backgroundColor: 'rgba(29, 185, 84, 0.3)',
      opacity: '1',
      pointerEvents: 'auto',
      cursor: 'pointer',
      zIndex: '20',
      boxShadow: '0 0 15px rgba(29, 185, 84, 1)',
      // border: '3px solid #1db954',
      
    }}
  >
    <img src={user?.profile_photo || undefined} alt="User" style={{ width: '100%', height: '100%', borderRadius: '30px' }} />
  </div>
)}
        {!showMarker &&  (<Box 
            ref={commentMarkersRef}
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              pointerEvents: 'none',
              zIndex: 10
            }}
          />)}
        </Box>
      </Box>

      {showComments && (
        <Box sx={{ marginTop: 4 }}>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Avatar 
              src={user?.profile_photo || undefined} 
              alt={user?.username || ''}
              sx={{ 
                width: 40, 
                height: 40,
                bgcolor: theme.palette.primary.main,
              }}
            >
              {user?.username[0].toUpperCase()}
            </Avatar>
            <TextField
              fullWidth
              variant="standard"
              placeholder="Add a timestamped comment ..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              onFocus={() => {
                if (currentTime === 0 || currentTime === duration) {
                setShowMarker(true);
                setNewCommentTime(Math.floor((Math.random()* 0.8 + 0.1) * duration)); // Generate random number between 10-90% of duration Set the temporary marker on focus 
              }
              else {
                setShowMarker(true);
                setNewCommentTime(currentTime);
              }
            }}
              onBlur={() => {
                setShowMarker(false); // Remove the temporary marker when focus is lost
              }}
              size="small"
              InputProps={{
                disableUnderline: true
              }}
              sx={{
                '& .MuiInputBase-root': {
                  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.05)',
                  borderRadius: '9999px',
                  padding: '0.5rem 1rem'
                },
                '& .MuiInputBase-input': {
                  color: theme.palette.text.primary
                }
              }}
            />
            <Button
              variant="contained"
              onClick={handleAddComment}
              disabled={!newComment.trim()}
              sx={{
                width: '40px',
                height: '40px',
                minWidth: '40px',
                padding: 0,
                borderRadius: '50%',
                backgroundColor: theme.palette.primary.main,
                color: 'white',
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark
                }
              }}
            >
              <Send sx={{ width: 20, height: 20 }} />
            </Button>
          </Box>
          {newComment.trim() && (
            <Typography sx={{ color: theme.palette.text.secondary, fontSize: '0.75rem', marginTop: 0.5 }}>
              Commenting at {formatTime(newCommentTime!)}, move cursor accordingly.
            </Typography>
          )}
        </Box>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 2 }}>
        <LikeButton 
          isLiked={isLiked} 
          onLikeToggle={handleLikeToggle}
          likeCount={likeCount} 
        />
        <CommentButton
          onClick={() => navigate(`/@${beat.author}/${beat.id}`)}
          commentCount={comments.length} 
        />
            <IconButton onClick={handleMenuOpen} sx={{ 
          color: 'text.secondary',
          '&:hover': {
            backgroundColor: 'rgba(0,0,0,0.1)'
          }
        }}>
        <SendLC />
    </IconButton>
    <Menu
        anchorEl={anchorEl} // Use anchorEl to position the menu
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
    >
        <MenuItem onClick={handleShareClick}>
        <ListItemIcon>
              <Link2 /> 
              </ListItemIcon>
            <Typography variant="body2">Copy Link</Typography>
        </MenuItem>
    </Menu>
      </Box>
    </Box>
  );
};

export default MAudioPlayer;
