import React from 'react';
import { Link } from 'react-router-dom'; // Ensure you have react-router-dom installed for navigation
import { Box, Typography, Button } from '@mui/material'; // Using Material-UI components
import './BattlesPage.css'; // Keep this for your styles

const BattlesPage: React.FC = () => {
    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
            <Typography variant="h4" component="h1" gutterBottom>
                Battles Coming Soon!
            </Typography>
            <Typography variant="body1" gutterBottom>
                Get ready for epic async beatbox battles and thrilling adventures. Stay tuned!
            </Typography>
            <Link to="https://discord.gg/YOUR_DISCORD_LINK" className="join-discord-button">
                Join Our Discord
            </Link>
        </Box>
    );
};

export default BattlesPage;