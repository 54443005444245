import React, { useState, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {
  Container,
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Avatar,
} from '@mui/material';
import { CirclePlay, CirclePlus, MicVocal } from 'lucide-react';
import RecentFeed from './pages/RecentFeed';
import RecordPage from './pages/RecordPage';
import LandingPage from './pages/LandingPage';
import Profile from './pages/Profile';
import { AuthProvider, useAuth } from './context/AuthContext';
import Sidebar from './components/Sidebar';
import './App.css';
import Guidelines from './pages/Guidelines';
import TopFeed from './pages/TopFeed';
import BeatPage from './pages/BeatPage';
import BattlesPage from './pages/Battles/BattlesPage';
import Navbar from './components/Navbar';

function AppContent() {
  const { isAuthenticated } = useAuth();
  const [mode, setMode] = useState<'light' | 'dark'>(
    localStorage.getItem('theme') === 'dark' ? 'dark' : 'light'
  );

  const createAppTheme = useCallback((mode: 'light' | 'dark') => {
    return createTheme({
      palette: {
        mode,
        primary: {
          main: mode === 'light' ? '#2E7D32' : '#4CAF50',
          light: mode === 'light' ? '#4CAF50' : '#45a049',
          dark: mode === 'light' ? '#1B5E20' : '#2E7D32',
        },
        secondary: {
          main: mode === 'light' ? '#45a049' : '#45a049',
          light: mode === 'light' ? '#b3b3b3' : '#b3b3b3',
          dark: mode === 'light' ? '#1B5E20' : '#1B5E20',


        },
        background: {
          default: mode === 'light' ? '#ffffff' : '#121212',
          paper: mode === 'light' ? '#ffffff' : '#181818',
        },
        text: {
          // primary: mode === 'light' ? '#000000' : '#ffffff',
          // secondary: mode === 'light' ? 'rgba(0, 0, 0, 0.7)' : 'rgba(255, 255, 255, 0.7)',
        },
        // divider: mode === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)',
      },
      typography: {
        fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
      },
      components: {
        MuiAppBar: {
          styleOverrides: {
            // root: {
            //   background: mode === 'light'
            //     ? '#ffffff'
            //     : '#000000',
            //   borderBottom: `1px solid ${mode === 'light' 
            //     ? 'rgba(0, 0, 0, 0.12)' 
            //     : 'rgba(255, 255, 255, 0.12)'}`,
            // },
          },
        },
        MuiButton: {
          styleOverrides: {
            // root: {
            //   textTransform: 'none',
            //   borderRadius: 8,
            // },
            // contained: {
            //   backgroundColor: mode === 'light' 
            //     ? 'rgba(0, 0, 0, 0.05)'
            //     : 'rgba(255, 255, 255, 0.1)',
            //   color: mode === 'light' ? '#000000' : '#ffffff',
            //   '&:hover': {
            //     backgroundColor: mode === 'light'
            //       ? 'rgba(0, 0, 0, 0.1)'
            //       : 'rgba(255, 255, 255, 0.15)',
            //   },
            // },
          },
        },
        MuiCard: {
          styleOverrides: {
            // root: {
            //   backgroundColor: mode === 'light'
            //     ? 'rgba(0, 0, 0, 0.02)'
            //     : 'rgba(255, 255, 255, 0.05)',
            //   borderRadius: 8,
            //   backdropFilter: 'blur(10px)',
            // },
          },
        },
        MuiDialog: {
          styleOverrides: {
            // paper: {
            //   background: mode === 'light'
            //     ? '#ffffff'
            //     : '#000000',
            //   borderRadius: 16,
            // },
          },
        },
      },
    });
  }, []);

  const [themeObj, setThemeObj] = useState(createAppTheme(mode));

  const toggleTheme = useCallback(() => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    setMode(newMode);
    setThemeObj(createAppTheme(newMode));
    localStorage.setItem('theme', newMode);
    document.documentElement.setAttribute('data-theme', newMode);
  }, [mode, createAppTheme]);

  const isMobile = window.innerWidth <= 768;
  const { user, logout } = useAuth();
  const [bottomNavValue, setBottomNavValue] = useState(0);
  const handleBottomNavChange = (event: React.SyntheticEvent, newValue: number) => {
    setBottomNavValue(newValue);
  };


  return (
    <ThemeProvider theme={themeObj}>
      <CssBaseline />
      <Box
        sx={{
          width: '100%',
          position: 'skicky', // Change to fixed
          top: 0,           // Position at top
          left: 0,          // Align to left
          zIndex: 1000,     // Ensure it's above other content
          display: { xs: 'block', md: 'none' }, // Show on mobile, hide on desktop
        }}
      >
        <Navbar onToggleTheme={toggleTheme} />
      </Box>
      <Container maxWidth="lg" sx={{ padding: 0 }}>
        <Box sx={{
          padding: 0,
          display: 'flex',
          minHeight: '100vh',
          position: 'relative',
        }}>
          {/* Navbar for mobile / Sidebar for desktop */}
          {isAuthenticated && (
            <>
              <Box
                sx={{
                  width: 275,
                  position: 'sticky',
                  top: 0,
                  height: '100vh',
                  display: { xs: 'none', md: 'block' }, // Hide on mobile, show on desktop
                }}
              >
                <Sidebar onToggleTheme={toggleTheme} />
              </Box>

            </>
          )}

          {/* Main Content */}
          <Box sx={{ flex: 1, }}>
            <Routes>
              {!isAuthenticated ? (
                <>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/:username/:beatId" element={<BeatPage />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </>
              ) : (
                <>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/explore/recent" element={<RecentFeed />} />
                  <Route path="/explore/top" element={<TopFeed />} />
                  <Route path="/record" element={<RecordPage />} />
                  <Route path="/guidelines" element={<Guidelines />} />
                  <Route path='/battle' element={<BattlesPage />} />
                  <Route path="/:username" element={<Profile onToggleTheme={toggleTheme} />} />
                  <Route path="/:username/:beatId" element={<BeatPage />} />
                  <Route path="*" element={<Navigate to="/explore/recent" replace />} />
                </>
              )}
            </Routes>
          </Box>

          {/* Bottom Navigation Bar (only for mobile) */}
          {isMobile && isAuthenticated && (
            <>
              <BottomNavigation
                value={bottomNavValue}
                onChange={handleBottomNavChange}
                sx={{

                  width: '100%',
                  position: 'fixed',
                  bottom: 0,
                  zIndex: 1000,
                  p: 1,
                  bgcolor: 'primary.main',
                  '& .MuiBottomNavigationAction-root': {
                    color: 'rgba(255, 255, 255, 0.7)',   // Unselected icons slightly transparent
                    '&.Mui-selected': {
                      color: 'white'                      // Selected icon fully white
                    }
                  }
                }}
                showLabels={false}
              >
                <BottomNavigationAction
                  icon={<CirclePlay />}
                  component={Link}
                  to="/explore/recent"
                />
                <BottomNavigationAction
                  icon={<MicVocal />}
                  component={Link}
                  to="/record"
                />
                <BottomNavigationAction
                  component={Link}
                  to={`/@${user?.username}`}
                  icon={<Avatar
                    src={user?.profile_photo || undefined}
                    alt={user?.username}
                    sx={{ width: 25, height: 25 }}
                  >
                    {user?.username?.[0]?.toUpperCase()}
                  </Avatar>}
                />
              </BottomNavigation>
            </>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
};

export default App;