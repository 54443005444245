// frontend/src/components/CommentItem.tsx

import React from 'react';
import { Avatar, IconButton, Stack, Typography,Dialog, DialogContent, Button, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { EllipsisVertical } from 'lucide-react';
import { Comment } from '../types';
import { useAuth } from '../context/AuthContext';
import { beats } from '../services/api';
import TimeAgo from 'timeago-react';

interface CommentItemProps {
  comment: Comment;
  onDeleteCallback: (id: number) => void;
}

const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

const CommentItem: React.FC<CommentItemProps> = ({ comment, onDeleteCallback }) => {
    const [open, setOpen] = React.useState(false);
    const { isAuthenticated, user } = useAuth();

    const handleClickToOpen = () => {
        setOpen(true);
    };

    const handleToDelete = async (id: number) => {
      try {
        if (!isAuthenticated) {
          return;
        }
        const response = await beats.deleteComment(id);
        setOpen(false);
        onDeleteCallback(id);
      } catch (error) {
        console.error('Error deleting comment:', error);
      }
  };

  const handleReport = () => {
    const email = "hello@spitbeat.com"; 
    const subject = `Report Comment: ID ${comment.id}`;
    const body = `I want to report the following comment:\n\nComment ID: ${comment.id}\nComment Text: ${comment.content}\n\nReason: [Enter reason here]`;
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    setOpen(false);
  };

    const handleToClose = () => {
        setOpen(false);
    };

  return (
    <li key={comment.id} className="m-2 pb-2">
      <Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%', justifyContent: 'space-between' }}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Link to={`/@${comment.username}`} style={{ textDecoration: 'none' }}>
            <Avatar 
              src={comment.user_photo || undefined} 
              alt={comment.username}
              sx={{ 
                width: 35, 
                height: 35,
                bgcolor: 'primary.main'
              }}
            >
              {comment.username[0].toUpperCase()}
            </Avatar>
          </Link>
          <Stack spacing={0} alignItems="left">
            <Stack direction="row" spacing={1} alignItems="center">
              <Link to={`/@${comment.username}`} style={{ textDecoration: 'none' }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {'@' + comment.username + " at "}
                </Typography>
              </Link>
              <Typography variant="body2" sx={{ color: 'primary.main', fontWeight:'bold' }}>
                <span>{formatTime(comment.timestamp)}</span>
              </Typography>
              {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                <span><TimeAgo datetime={new Date(comment.created_at+'Z')}/></span>
              </Typography> */}
            </Stack>
            <Typography variant="body1" sx={{ color: 'text.primary', mt: -0.1 }}>
              {comment.content}
            </Typography>
          </Stack>
        </Stack>
        <IconButton aria-label="more options" onClick={handleClickToOpen}>
          <EllipsisVertical />
        </IconButton>
        <Dialog open={open} onClose={handleToClose}>
      <DialogContent>
        <Stack spacing={1} minWidth={200}>
          {comment.username === user?.username && (
            <Button onClick={() => handleToDelete(comment.id)} color="error" autoFocus>
              Delete
            </Button>
          )} 
          {comment.username !== user?.username && (
            <Button onClick={handleReport} color="error" autoFocus>
              Report
            </Button>
          )}
          <Divider />
          <Button onClick={handleToClose} color="primary" autoFocus>
            Close
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
      </Stack>
    </li>
  );
};

export default CommentItem;