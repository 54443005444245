import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  Button,
} from '@mui/material';
import { MessageSquareHeart, MicVocal, EllipsisVertical, LogOut, Moon, Sun, User, CirclePlay, Star, Coffee } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import { useTheme } from '@mui/material/styles';
import RecordBeat from './RecordBeat';

interface SidebarProps {
  onToggleTheme: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ onToggleTheme }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showRecordDialog, setShowRecordDialog] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState<string | null>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
    handleMenuClose();
  };

  const handleRecordComplete = () => {
    setShowRecordDialog(false);
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {/* Logo */}
      <Box sx={{ pt: 2, pb: 2 }}>
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{
            fontWeight: 'bold',
            textDecoration: 'none',
            color: theme.palette.text.primary,
            fontFamily: 'Bebas Neue, sans-serif',
          }}
        >
          Spitbeat
        </Typography>
      </Box>

      {/* Explore Section */}
      <Typography variant="body2" fontWeight='light' sx={{ mt: 2 }}>
        Explore
      </Typography>
      <List sx={{ mt: 1 }}>
        <ListItem disablePadding>
          <ListItemButton 
            component={Link} 
            to="/explore/recent" 
            sx={{ 
              borderRadius: 28, 
              bgcolor: selectedItem === 'recent' ? 'action.hover' : 'transparent' 
            }} 
            onClick={() => setSelectedItem('recent')}
          >
            <ListItemIcon>
              <CirclePlay />
            </ListItemIcon>
            <ListItemText 
              primary="Recent" 
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton component={Link} to="/explore/top" sx={{ borderRadius: 28 , bgcolor: selectedItem === 'top' ? 'action.hover' : 'transparent' }} onClick={() => setSelectedItem('top')}>
            <ListItemIcon>
              <Star />
            </ListItemIcon>
            <ListItemText primary="Top" />
          </ListItemButton>
        </ListItem>
        {/* <ListItem disablePadding>
            <ListItemButton component={Link} to="/explore/liked" sx={{ borderRadius: 28 }}>
              <ListItemText primary="Liked" />
            </ListItemButton>
          </ListItem> */}
      </List>

      {/* Library Items */}

        <Typography variant="body2" fontWeight='light' sx={{ mt: 2 }}>
          Library
        </Typography>
        <List sx={{ flex: 1 }}>
        <ListItem disablePadding>
          {user && (
            <ListItemButton
              component={Link}
              to={`/@${user.username}`}
              sx={{ borderRadius: 28,
              bgcolor: selectedItem === 'mybeats' ? 'action.hover' : 'transparent' 
            }} 
            onClick={() => setSelectedItem('mybeats')}
            >
              <ListItemIcon>
                <User />
              </ListItemIcon>
              <ListItemText primary="My Bars" />
            </ListItemButton>
          )}
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton 
            component={Link} 
            to="/guidelines" 
            sx={{ 
              borderRadius: 28, 
              bgcolor: selectedItem === 'guidelines' ? 'action.hover' : 'transparent' 
            }} 
            onClick={() => setSelectedItem('guidelines')}
          >
            <ListItemIcon>
              <MessageSquareHeart /> {/* You can replace this icon with any other icon you prefer */}
            </ListItemIcon>
            <ListItemText 
              primary="Guidelines" 
              sx={{ fontWeight: selectedItem === 'guidelines' ? 'bold' : 'normal' }} 
            />
          </ListItemButton>
        </ListItem>

        {/* Post Button */}
        <ListItem sx={{ mt: 2 }}>
          <Button
            variant="contained"
            fullWidth
            startIcon={<MicVocal />}
            onClick={() => setShowRecordDialog(true)}
            sx={{
              borderRadius: 28,
              py: 1.5,
              textTransform: 'none',
              fontSize: '1.1rem',
              fontWeight: 'bold',
              bgcolor: 'primary.main',
              color: '#fff',
              '&:hover': {
                bgcolor: 'primary.dark',
              },
            }}
          >
            Post
          </Button>
        </ListItem>
      </List>

      {/* User Profile Section */}

      <Box sx={{ p: 2 }}>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            p: 1,
            borderRadius: 30,
            '&:hover': {
              bgcolor: 'action.hover',
            },
            position: 'relative',
            pr: 2,
          }}
        >
          <Avatar
            src={user?.profile_photo || undefined}
            alt={user?.username}
            sx={{ width: 40, height: 40 }}
          >
            {user?.username?.[0]?.toUpperCase()}
          </Avatar>
          <Box sx={{
            flex: 1,
            minWidth: 0,
            mr: 1
          }}>
            <Typography
              variant="subtitle2"
              fontWeight="bold"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}
            >
              {user?.username}
            </Typography>
          </Box>
          <IconButton
            size="small"
            onClick={handleMenuClick}
            aria-label="user menu"
            sx={{
              padding: 0.5,
              '&:hover': {
                bgcolor: 'action.hover',
              }
            }}
          >
            <EllipsisVertical fontSize="small" />
          </IconButton>
        </Box>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiMenuItem-root': {
              padding: '10px 20px',
              '&:hover': { backgroundColor: '#f0f0f0' }
            }
          }}
        >
          {/* <MenuItem component="a" href="https://buymeacoffee.com/shasha98sr" target="_blank" rel="noopener noreferrer" aria-label="Buy Me a Coffee">
            <Coffee size={15} />
            Buy Me a Coffee
          </MenuItem> */}
          <MenuItem onClick={onToggleTheme} aria-label="Toggle theme">
            {theme.palette.mode === 'dark' ? (
              <>
                <Moon size={15} />
                Light Mode
              </>
            ) : (
              <>
                <Sun size={20} />
                Dark Mode
              </>
            )}
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleLogout} aria-label="Logout">
            <LogOut size={20} />
            Logout
          </MenuItem>
        </Menu>
      </Box>

      {/* Record Beat Dialog */}
      <RecordBeat
        open={showRecordDialog}
        onClose={() => setShowRecordDialog(false)}
        onUploadComplete={handleRecordComplete}
      />
    </Box>
  );
};

export default Sidebar;
